<template>
  <div class="content">
        <button v-if="isCart" class="allclearbn" @click="clearAllCart">カートの内容を削除する</button>
    <h1>お申込内容の確認</h1>
    <p><strong>まだ完了していません。</strong> <br>以下の内容でお申し込みを行います。よろしいですか？</p>

    <plugin_cartFlow flow="7" />

    <div class="steps">
      <div class="steptitle">ステップ7：ご注文の最終確認</div>
      <div class="statusLoading" v-if="!loaded">
        読み込み中
      </div>
      <div class="stepcontent" v-if="loaded">
        
        

        
    <orderview :orderData="orderData" flow="confirm"></orderview>



        <h3>お支払い方法

                    
<span class="backlink"><router-link @click.native="scrollToTop" :to="{ path: '/item/' + label + '/payment', query: { rd: 'back' }}">編集</router-link></span>



        </h3>

        <div v-for="(payment, p) in computed.payments" :key="'pay' + p">
          <p v-if="record.fields.payment_type.value == payment.ID">
            {{ payment.page_title }}
          </p>
        </div>

        <div class="itemcompute dataTable">
          <dl>
            <dt>商品金額合計</dt>
            <dd>{{ computed.price_taxation }}円<span class="tax">{{computed.taxview}}</span></dd>
          </dl>
          <dl>
            <dt>合計配送料</dt>
            <dd>{{ computed.delivercost }}円</dd>
          </dl>
          <dl v-if="computed.payment_customdeliver != 0">
            <dt>カスタム配送料</dt>
            <dd>{{ computed.payment_customdeliver }}円</dd>
          </dl>
          <dl v-if="computed.payment_fee != 0">
            <dt>手数料</dt>
            <dd>{{ computed.payment_fee }}円</dd>
          </dl>
          
          
          <dl v-if="computed.campains && computed.campains.length">
            <dt>適用されたキャンペーン</dt>
            <dd v-for="campain,c1 in computed.campains" :key="c1">
              <div class="camp_title">{{ campain.title }}</div>
              <div class="camp_desc">{{ campain.description }}</div>

            </dd>
          </dl>
          <dl v-if="computed.discount!='0'">
            <dt>割引</dt>
            <dd>-{{ computed.discount }}円</dd>
          </dl>
          

          <dl>
            <dt v-if="computed.taxtype == 'include'">(うち消費税)</dt>
            <dt v-if="computed.taxtype == 'exclude'">消費税</dt>
            <dd>{{ computed.tax }}円</dd>
          </dl>
          <dl>
            <dt>合計金額</dt>
            <dd>{{ computed.total_amount }}円<span class="tax">{{computed.taxview}}</span></dd>
          </dl>
        </div>
      </div>
    </div>

    <div class="footbns" v-if="!submitChecking">
<a class="bn_back" @click.prevent="back">前へもどる</a>

      <a class="bn_next" @click.prevent="submit">お申込みの実行</a>
    </div>

    <div class="footbns" v-else>
      <a class="bn_back" @click.prevent>(少々お待ちください...)</a>
    </div>

    <!--
<router-link @click.native="scrollToTop" :to="'/item/'+label+'/complete'">お申込みの実行</router-link>
-->

    <dialogalert
      v-model="visible_error"
      :title="error_title"
      :info="error_info"
      button_ok="OK"
      :ok_fn="ok_fn"
    />
    
<fullloader :loading="fullloading" />

  </div>
</template>

<script>
import orderview from "@/components/orderview.vue";
export default {
  components: { orderview},
  data: function () {
    return {
      values: {},
      previewHTML: "",
      previewImages: [],
      tagged_values: [],
      jumpurl: "",
      submitChecking: false,
      imgviewflg:[],
    };
  },
  created: function () {
      this.fullloading=true;
    this.$parent.middle = 2;
    this.loaded_countmax = 1;
    //まず商品定義を読み込む
    //alert(this.$route.params.label)
    /*
    this.get_itemoption(this.$route.params.label).then(() => {
      this.values = this.record.fields.items.subfields;
      var tagged_values = [];
      for (let i = 0; i < this.unit; i++) {
        tagged_values.push(this.get_tagged_values(this.get_option_value(i)));
      }
      this.tagged_values = tagged_values;
      this.loaded_countup();
    });*/

    //var record=this.getSession("record");
    //まず商品定義を読み込む
    /*
    this.fetch("previewcreate", {
      label: this.$route.params.label,
      record: this.record,
    }).then((data) => {
      console.log(data);
      //this.previewHTML=data;
      this.previewImages = data.preview;
      this.loaded_countup();
    });*/

    this.get_totalamount().then((data) => {
      
        this.orderData = this.record;
        this.orderData.fields.prices.value=data;
      console.log("computed",data);
      this.computed = data;
      if(this.computed.taxtype == 'include')this.computed.taxview='(税込)';
      if(this.computed.taxtype == 'exclude')this.computed.taxview='(税別)';
      this.loaded_countup();


    });
  },
  computed: {
    label: function () {
      return this.$route.params.label;
    },
    ID:function(){
      return this.$route.params.ID;
    },
  },
  methods: {
    imgvisible:function(index){
      if(typeof this.imgviewflg[index]=="undefined"||!this.imgviewflg[index]){
        return false;
      }
      return true
    },
    imghide:function(index){
      this.$set(this.imgviewflg,index, false);
      console.log('this.imgviewflg[index]',this.imgviewflg[index])
    },
    imgview:function(index){
      this.$set(this.imgviewflg,index, true);
      console.log('this.imgviewflg[index]',this.imgviewflg[index])
    },
    hiddentxt: function (str) {
      return "*".repeat(str.length);
    },
    fn: function () {
      console.log("fn test");
    },
    ok_fn: function () {
      this.$router.push(this.jumpurl);
    },
    preview: function () {},
    submit: function () {
      this.submitChecking = true;
      this.fullloading=true;
      var record = this.getSession("record");
      //ログイン済みの場合はトークン、非会員の場合はuserを「-1」にする。
      var token = this.get_localStorage("token");
      if (!token) record.user = "-1";
      var cardToken = this.getSession("cardToken");
      this.fetch("order_submit", {
        token: token,
        cardtoken: cardToken ? cardToken.token : "",
        record: record,
        mode: "submit",
      }).then((data) => {
      this.fullloading=false;
        console.log(data);
        if (typeof data.record != "undefined")
          this.$set(
            this.record,
            "fields",
            Object.assign(this.record.fields, data.record.fields)
          );
        if (typeof data.error != "undefined") {
          console.log(data.error);
          this.visible_error = true;
          this.error_info =
            "以下の理由のため、完了できませんでした。<br>以下の内容をご確認の上、前の画面に戻り内容を修正してください。<br>";
          //this.error_title="以下をご確認ください";
          this.jumpurl = "";
          for (let i = 0; i < data.error.length; i++) {
            this.error_info += "・ " + data.error[i].msg + "<br>";
            if (data.error[i].id == "field_payment_cardtoken") {
              this.setSession("cardToken", {});
              this.jumpurl = "payment";
            }
            if (data.error[i].id.indexOf("field_mydeliver") != -1)
              this.jumpurl = "deliver";
          }
          //if(this.jumpurl) this.$router.push(jumpurl);

          this.submitChecking = false;
        } else {

          //成功したのでデータを削除
          this.clearAllCart_exec();
          console.log("成功したのでデータを削除");
          //ルーティングだと、残った変数を使用してlocalstrageが更新保存されてしまうので、
          //情報を空にするためにリダイレクトで移動する
try{
          
window.yjDataLayer = window.yjDataLayer || [];
function ytag() { yjDataLayer.push(arguments); }
ytag({"type":"ycl_cookie"});


window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', 'AW-10776081716');

gtag('event', 'conversion', { 'send_to': 'AW-10776081716/gp_SCKCz3PUCELTqt5Io', 'transaction_id': '' });

} catch (error) {  console.error("ad script error",error);
  // expected output: ReferenceError: nonExistentFunction is not defined
  // Note - error messages will vary depending on browser
}


          window.location.href="status/" + data.result.ordertoken;

          //this.$router.push("status/" + data.result.ordertoken);
        }
      });
      //alert("submit!");
    },
    get_option_value(index) {
      if (this.values[index].options.value) {
        return JSON.parse(this.values[index].options.value);
      } else {
        return [];
      }
    },
    back:function(){
      this.scrollToTop();
          if(this.ID){
          this.$router.push('/item/'+this.label+'/payment/update/'+this.ID);
          }else{
          this.$router.push('/item/'+this.label+'/payment');
          }
    },
  },
};
</script>

<style scoped lang="scss">
$sp: 680px; // スマホ
@mixin sp {
    @media (max-width: ($sp)) {
        @content;
    }
}
.stepcontent {
  padding: 1.5em;
	@include sp{
		padding:0.5em;
	}
}
h1 {
  margin-top: 1em;
  color: #383838;
  font-size: 2em;
}
h2 {
  motion-path: 1.5em;
}
h3{
  margin-top: 1em;
  margin-bottom: .6em;
}
.content {
  color: #000;
}
.formBox {
  text-align: left;
}
.previewbox {
  background: rgb(245, 245, 245);
  text-align: left;
  padding: 0.5em;
  ul{
    list-style: none;
  }
}
.orderitem{
  padding: 0 1.5em;
  flex-grow: 10;
}
.letter_previewimg {
  border: solid 1px #b0b0b0;
  width: 200px;
  cursor: pointer;
  &:hover{
    opacity: .8;
  }
}
.confirmbox {
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
}
.statusLoading{
  padding: 40vh 0;
  text-align: center;
}
.dataTable {
  dl {
    margin-bottom: 1em;
  }
  dt {
    font-size: 0.7em;
    font-weight: bold;
    color: #959595;
    border-bottom: solid 1px #ccc;
  }
  dd {
    font-size: 1em;
    color: rgb(34, 34, 34);
    padding-left: 0.5em;
  }
  &.sender {
  }
}
.itemcompute{
  margin-top: 1.5em;
  text-align: right;
  dt{
    font-size: .9em;
    border-bottom: solid 1px #ccc;
  }
  dd{
    margin-bottom: 1em;

  }
  .amount{
  dt{
    font-size: 1.1em;
  }
  dd{
    font-size: 1.6em;
  }
  }
}



$sp: 480px;
@mixin sp {
    @media (max-width: ($sp)) {
        @content;
    }
}
.vel-toolbar{
  display: flex;
  position: fixed;
  bottom: 5vh;
  width: 100%;
  justify-content: center;
  @include sp{
    font-size: 7vw;
  }
  svg{ 
    width: 1em;height: 1em;
  }
  .toolbar-btn{
    cursor: pointer;
    background: rgb(179, 179, 179);
    padding: .3em 1em;
    border-radius: .5em;
    margin-right: .6em;
    &:hover{
      opacity: .8;
    }
  }
}
.backlink{
  float: right;
  a{
    color: #fff;
    background: rgb(160, 160, 160);
    padding: .3em 1em;
    display: inline-block;
  text-decoration: none; font-size: .75em;
  border-radius: .7em;
  font-weight: normal;
  }
}
.camp_title{
  font-size: .8em;
  color: #383838;
  font-weight: bold;
  padding: .7em;
}
.camp_desc{
  background: rgb(251, 251, 251);
  border-radius: .6em;
  padding: .7em;
  font-size: .7em;
}
</style>